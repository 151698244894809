@import './node_modules/bourbon-neat/core/neat';

$neat-grid: ( columns: 12, gutter: 15px);
$small-grid: ( columns: 12, gutter: 15px, media: 576px);
$medium-grid: ( columns: 12, gutter: 15px, media: 768px);
$large-grid: ( columns: 12, gutter: 15px, media: 992px);
$extra-large-grid: ( columns: 12, gutter: 15px, media: 1200px);
$grid-columns: 12;
$app-prefix: 'md';

.#{$app-prefix}-grid {
  @include grid-container;
}

.#{$app-prefix}-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  align-items: baseline;
}

@for $i from 1 through $grid-columns {
  .#{$app-prefix}-col-xs-#{$i} {
    @include grid-column($i)
  }
}

@for $i from 1 through $grid-columns {
  .#{$app-prefix}-col-sm-#{$i} {
    @include grid-media($small-grid) {
      @include grid-column($i);
    }
  }
}

@for $i from 1 through $grid-columns {
  .#{$app-prefix}-col-md-#{$i} {
    @include grid-media($medium-grid) {
      @include grid-column($i);
    }
  }
}

@for $i from 1 through $grid-columns {
  .#{$app-prefix}-col-lg-#{$i} {
    @include grid-media($large-grid) {
      @include grid-column($i);
    }
  }
}

@for $i from 1 through $grid-columns {
  .#{$app-prefix}-col-xl-#{$i} {
    @include grid-media($extra-large-grid) {
      @include grid-column($i);
    }
  }
}

.navbar {
  width: 100%;
  position: relative;
  background-color: #57597b;
  height: 64px;
  line-height: 64px;
  ul {
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 15px;
      transition: background-color 0.2s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.container-fluid {
  padding: 0 15px;
}

.btn {
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #000;
  transition: box-shadow 0.25s ease-in-out;
  background-color: #90c965;
  line-height: 2;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 15px;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(194, 194, 194);
  }
}

.driver-list {
  display: inline-block;
  border: 2px solid #ccc;
  height: 500px;
  width: 275px;
  .driver {
    margin: 0;
    padding: 15px;
    text-align: center;
    transition: background-color 0.5s;
    &:hover {
      background-color: #ccc;
    }
  }
}

.inline-icon {
  p {
    display: inline-block;
    margin: 0;
  }
  i {
    float: right;
    cursor: pointer;
  }
}

.config-list {
  border: 2px solid #ccc;
  max-height: 500px;
  overflow: auto;
  .warning {
    background-color: #ff5757;
  }
  .config-item {
    padding: 15px;
    border-bottom: 2px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: #ccc;
    }
    &:last-child {
      border-bottom: none;
    }
    .fa-trash {
      transition: color 0.2s;
      &:hover {
        color: red;
      }
    }
  }
}

.command-bar {
  display: flex;
  .btn {
    margin-right: 15px;
  }
}

.address-form {
  margin-top: 25px;
  max-width: 800px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #000;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  background-color: #b2b6c0;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 2px 15px #5d6ea0;
  z-index: 2;
  h1 {
    margin-top: 0;
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(211, 211, 211, .5);
  top: 0;
  left: 0;
  z-index: 1;
}

.toast {
  padding: 15px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 99;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0px 0px 10px #7e7e7e;
  p {
    margin: 0;
  }
}

.toast-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.toast-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.route-warning {
  color: #721c24;
}

.fade-in {
  opacity: 1;
}

.list-element-transition-enter {
  opacity: 0.01;
}

.list-element-transition-enter.list-element-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.list-element-transition-exit {
  opacity: 1;
}

.list-element-transition-exit.list-element-transition-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

input {
  display: block;
  margin-bottom: 15px;
  padding: 5px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #7e7e7e;
  transition: box-shadow 0.25s ease-in-out;
  width: 100%;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(194, 194, 194);
  }
}

.text-center {
  text-align: center;
}

input:disabled,
button:disabled {
  cursor: not-allowed;
}

hr {
  border: 1px solid #ccc;
}

body {
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}